import { ComponentOptions } from "vue";

function getTitle(vm: any): string | undefined {
  const { title } = vm.$options;
  if (title) {
    return typeof title === "function"
      ? (title as () => string).call(vm)
      : title;
  }
}

export default {
  created(): void {
    const title = getTitle(this);
    if (title) {
      document.title = title;
    }
  },
} as ComponentOptions;
